/* src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Añade esto a tu archivo tailwind.css o index.css */
.clip-diagonal-orange {
    clip-path: polygon(0 100%, 100% 0, 100% 30%, 0 100%);
    background-color: #f7921e; /* Color gris oscuro */
    height: 100px;
    position: relative;
}

.clip-diagonal-turquoise {
    clip-path: polygon(0 100%, 100% 0, 100% 30%, 0 100%);
    background-color: #8CD8E5; /* Color gris oscuro */
    height: 100px;
    position: relative;
}

.clip-diagonal-pink {
  clip-path: polygon(0 100%, 100% 0, 100% 30%, 0 100%);
  background-color: #FF73B9; /* Color gris oscuro */
  height: 100px;
  position: relative;
}

.clip-diagonal-black {
    clip-path: polygon(0 100%, 300% -120%, 100% 100%, 0 100%);
    background-color: #fff;
    height: 100px;
    position: relative;
    bottom: 0;
    z-index: 999;
    margin-top: -100px;
}

.clip-diagonal-black-200-b {
    clip-path: polygon(100% 30%, 100% -120%, 100% 300%, 0% 100%);
    margin-top: -200px;
}

.clip-diagonal-black-200-t {
    clip-path: polygon(100% 30%, 100% -120%, 100% 300%, 0% 100%);
    margin-bottom: -200px;
    transform: rotateX(180deg);
}

.clip-diagonal-black-200-b, .clip-diagonal-black-200-t {
    height: 200px;
    background-color: #fff;
    position: relative;
    z-index: 999;
}

.clip-diagonal-white {
    clip-path: polygon(0 100%, 300% -120%, 100% 150%, 0 100%);
    background-color: #fff;
    height: 100px;
    position: relative;
    bottom: 0;
    z-index: 999;
    margin-top: -100px;
}

.clip-diagonal3 {
    clip-path: polygon(-15% 100%, 100% 0, 100% 15%, 0 100%);
    background-color: #383838; /* Color gris oscuro */
    height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
}

.clip-diagonal4 {
    clip-path: polygon(100% 0, 0% -120%, 0% 100%, 0 85%);
    background-color: #fff;
    height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 999;
}

.lista {
    list-style: disc;
}

.flip-card {
  width: 326px;
  height: 431px;
  perspective: 1000px;
  position: relative;
  max-width: 100%;
}

@media (max-width: 600px) {
    .clip-diagonal-orange, .clip-diagonal-turquoise, .clip-diagonal-pink, .clip-diagonal-black, .clip-diagonal-white, .clip-diagonal3, .clip-diagonal4 {
        height: 70px;
    }
    .clip-diagonal-black, .clip-diagonal-white {
        margin-top: -70px;
    }
    .flip-card {
      width: 300px;
      height: 400px;
    }
}

@keyframes moveAndColorChange {
    0% {
      color: white;
      transform: translateY(-10px) scale(1);
    }
    33% {
      color: orange;
      transform: translateY(10px) scale(1.05);
    }
    66% {
      color: pink;
      transform: translateY(-10px) scale(1);
    }
    100% {
      color: white;
      transform: translateY(10px) scale(1.05);
    }
  }
  
  .flip-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    transform: rotateY(0deg);
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }

  .flip-container {
    width: 100%;
    height: 300px;
    perspective: 1000px;
    position: relative;
  }
  
  .flip-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .flip-container:hover .flip-inner {
    transform: rotateY(180deg);
  }
  
  .flip-front, .flip-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .flip-front {
    z-index: 2;
    transform: rotateY(0deg);
    border-radius: 30px;
    background-color: #fff;
    padding: 0 20px;
  }
  
  .flip-front img {
    margin: 0 auto;
  }

  .flip-back {
    transform: rotateY(180deg);
    background-color: #383838;
    color: #000;
    padding: 1rem;
    text-align: center;
    border-radius: 30px;
  }
  
  .gallery-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;  /* Altura general de la galería */
  }

  h2 span > strong, h3 span > strong, h4 span > strong, h2 > strong, h3 > strong, h4 > strong {
    width: 100%;
    display: block;
  }

@media (min-width: 400px){
  .gallery-item.active {
    background-color: #383838;
  }
}

@media (max-width: 400px){
  .gallery-item {
    width: 140px;
    height: 200px!important;
  }
  .gallery-container {
    flex-flow: row wrap;
    height: auto;  /* Altura general de la galería */

  }
  .gallery-item.active {
    flex-flow: column;
    position: fixed!important;
    top: 0;
    height: 100vh!important;
    left: 0;
    width: 100vw!important;
    background-color: #383838ed;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999!important;
  }
  .gallery-info {
    justify-content: flex-start!important;
  }
}

.gallery-item {
    height: 380px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    cursor: pointer;
}

.gallery-item.active {
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 10;
    transform: scale(1);
    opacity: 1;
}

.gallery-item:not(.active) {
    opacity: 0.7;
}

.gallery-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: transform 0.5s ease;
}

.gallery-item.active .gallery-image {
    width: 30%;
}

.gallery-info {
    width: 70%;
    transform: translateX(0%);
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    padding: 10px 20px;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.gallery-item.active .gallery-info {
    opacity: 1;
}

.swiper-button-prev:after, .swiper-button-next:after {
  color: #fff!important;
}

@media (max-width: 400px){
  .swiper-button-prev {
    left: -30px!important;
  }
  .swiper-button-next {
    right: -30px!important;
  }
  .swiper-button-prev::after, .swiper-button-next::after {
    font-size: 20px!important;
  }
}